<template>
  <div>
    <VCard title="Create Awesome 🙌">
      <VCardText>Coming Soon</VCardText>
    </VCard>
  </div>
</template>

<script setup>
definePage({
  meta: {
    // unauthenticatedOnly: true,
    action: 'read',
    subject: 'user',
  },  
})
</script>
