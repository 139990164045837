import { userAuthState } from '@/@core/auth'
import { router } from '@/plugins/2.router'
import { ofetch } from 'ofetch'

const handleUnauthorizedResponse = () => {
  userAuthState().setToken(null)
  userAuthState().setUser(null)
  localStorage.removeItem('accessToken')

  // Avoid adding a redirect parameter if we're already heading to /login
  if (router.currentRoute.value.name !== 'login') {
    const redirectPath = router.currentRoute.value.fullPath

    router.push({ name: 'app-login', query: { to: redirectPath === '/app/login' ? undefined : redirectPath } })
  }
}

export const $api = ofetch.create({
  baseURL: import.meta.env.VITE_API_BASE_URL || '/api',
  async onRequest({ options }) {
    options.headers = {
      Accept: 'application/json',
      ...options.headers,
    }
    if (!options.headers['ContentType']) {
      options.headers['ContentType'] = 'application/json'
    }
    const accessToken =localStorage.getItem('accessToken')
    if (accessToken) {
      options.headers = {
        ...options.headers,
        
        Authorization: `Bearer ${accessToken}`,
      }
    }
  },
  onResponse({ response, error }) {
    if (response.status === 200|| response.status === 201|| response.status === 204) {
      // useToast().success('Success', 'success')
    } else if (response.status === 401) {
      handleUnauthorizedResponse()
    } else if (response.status === 403) {
      useToast().error('You are not authorized to access this resource', 'error')
    } else if (response.status === 404) {
      // redirect to login page
      useToast().error('Not Found', 'error')
    } else if (response.status === 500) {
      // redirect to login page
      useToast().error('Server Error', 'error')
    }
    else if (response.status === 422) {
      useToast().error(response?._data?.message || 'Unprocessable Entity', 'error')
    }
    
    return response
  },
  onRequestError({ error }) {
    // if error code is 401, redirect to login page
    if (error.status === 401) {
      useToast().error('Unauthenticated', 'error')
      userAuthState().setToken(null)
      userAuthState().setUser(null)
      if (router.currentRoute.value.name !== 'login'){
        router.push({ name: 'app-login' }) // use the router instance here
      }
    } else if (error.status === 403) {
      useToast().error('You are not authorized to access this resource', 'error')
      router.push({ name: 'not-authorized' }) // use the router instance here
    }

    useToast().error(error, 'error')
  },
})
