<script setup>
import FilterComponent from '@/@core/components/custom/FilterComponent.vue'
import AnalyticsTicketTracker from '@/@core/components/feedback/AnalyticsTicketTracker.vue'
import FeedbackResponseDrawer from '@/views/feedback/feedback-panel/drawer/FeedbackResponseDrawer.vue'
import TicketTable from '@/views/feedback/ticket-management/TicketTable.vue'
import TicketDrawer from '@/views/feedback/ticket-management/drawer/TicketDrawer.vue'
import CustomerCommunicationDrawer from '@/views/feedback/ticket-management/drawer/customer/CustomerCommunicationDrawer.vue'



definePage({
  meta: {
    name: 'ticket-management',
    subject: 'feedback.ticket',
    action: 'read',
  },  
})


const route = useRoute()
const ticketFilters = ref({})
const { data: filterOptions } = await useApi(createUrl('/feedbacks/ticket-filters'))

const isTicketDrawerVisible = ref(false)
const isFeedbackResponseDrawerVisible = ref(false)
const ticketDrawerData = ref()
const feedbackResponseDrawerData = ref()
const refTicketTable = ref()
const isCommunicationDrawerVisible = ref(false)
const communicationDrawerData = ref()
const communicationDrawerType = ref('View')

const showTicket = feedbackData => {
  setTimeout(() => {
    ticketDrawerData.value = feedbackData
    isTicketDrawerVisible.value = true
  }, 1000)
}

// check if ticket id is present in the route
if(route.query.ticket){
  const ticketId = route.query.ticket
  const { data: ticketData } = await useApi(createUrl(`/feedbacks/tickets/${ticketId}`))
  if(ticketData.value){
    showTicket(ticketData.value)
  }
}

const showFeedbackResponse = (feedbackData, highlightedFeedbackResponse=null) => {
  feedbackResponseDrawerData.value = feedbackData
  feedbackResponseDrawerData.value.highlighted_feedback_response = highlightedFeedbackResponse
  isFeedbackResponseDrawerVisible.value = true
}

const showCommunicationDrawer= (feedbackData, type, communicationFor='') => {
  communicationDrawerData.value = feedbackData
  communicationDrawerData.value.communicationFor = communicationFor
  communicationDrawerType.value = type
  isCommunicationDrawerVisible.value = true
}

const refTicketTracker =ref(null)

const refreshTicketData = async (closeDrawer=true) => {
  if(closeDrawer){
    isTicketDrawerVisible.value = false
  }
  refTicketTable.value?.fetchTickets()
  refTicketTracker.value?.fetchStatusGraph()
}
</script>

<template>
  <section>
    <VRow class="match-height mb-6">
      <VCol
        cols="12"
        md="6"
        lg="6"
      >
        <FilterComponent
          v-model:filters="ticketFilters"
          :filters-options="filterOptions"
        />
      </VCol>
      <VCol
        cols="12"
        md="6"
        lg="6"
      >
        <AnalyticsTicketTracker
          ref="refTicketTracker"
          :ticket-filters="ticketFilters"
          page="ticket-management"
        />
      </VCol>
    </VRow>
    <TicketTable
      ref="refTicketTable"
      :ticket-filters="ticketFilters"
      page="ticket-management"
      @show-ticket="showTicket"
      @show-feedback-response="showFeedbackResponse"
      @show-communication-drawer="showCommunicationDrawer"
    />
  
    <FeedbackResponseDrawer
      v-model:isDrawerOpen="isFeedbackResponseDrawerVisible"
      :feedback-data="feedbackResponseDrawerData"
      :highlighted-feedback-question="feedbackResponseDrawerData?.highlighted_feedback_question"
      @show-communication-drawer="showCommunicationDrawer"
    />
    <CustomerCommunicationDrawer
      v-model:isDrawerOpen="isCommunicationDrawerVisible"
      :customer-data="communicationDrawerData"
      :active-tab="communicationDrawerType"
    />
    
    <TicketDrawer
      v-model:isDrawerOpen="isTicketDrawerVisible"
      :ticket-data="ticketDrawerData"
      @refresh-ticket-data="refreshTicketData"
      @show-feedback-response="showFeedbackResponse"
      @show-communication-drawer="showCommunicationDrawer"
    />
  </section>
</template>
