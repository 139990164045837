import { userAuthState } from "@/@core/auth"
import { ability } from '@/plugins/casl/ability'



export const redirects = [
  // ℹ️ We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/app/',
    name: 'app',
    redirect(to) {
      const authState =  userAuthState()
      const isLoggedIn =authState.isLoggedIn

      if (!isLoggedIn)
        return { name: 'app-login', query: to.query }

      if(authState.token && authState.user && ability.can('read', 'dashboard.feedback') || ability.can('read', 'dashboard.ticket')){
        return { name: 'app-dashboard' }      
      }
      
      return { name: 'app-login', query: to.query }
    },
  },
  {
    path: '/',
    name: 'index',
    redirect(to) {
      const authState =  userAuthState()
      const isLoggedIn =authState.isLoggedIn

      if (!isLoggedIn)
        return { name: 'app-login', query: to.query }

      if(authState.token && authState.user){
        return { name: 'app-dashboard' }
      }
      
      return { name: 'app-login', query: to.query }
    },
  },
]

export const routes = [
  {
    path: '/app/users/:id/:tab',
    name: 'app-user-tab',
    component: () => import('@/pages/app/users/user.vue'),
    meta: {
      navActiveLink: 'users',
    },
  },
  {
    path: '/app/users/add',
    name: 'app-user-add',
    component: () => import('@/pages/app/users/user.vue'),
    meta: {
      navActiveLink: 'users',
    },
  },

  {
    path: '/app/questionnaire/add',
    name: 'app-question-add',
    component: () => import('@/pages/app/questionnaire/question.vue'),
    meta: {
      subject: 'master.question',
      action: 'create',
      navActiveLink: 'questionnaire',
    },
  },
  {
    path: '/app/questionnaire/:id',
    name: 'app-question-tab',
    component: () => import('@/pages/app/questionnaire/question.vue'),
    meta: {
      subject: 'master.question',
      action: 'update',
      navActiveLink: 'questionnaire',
    },
  },
  
  {
    path: '/app/feedback-entry/:type/collect',
    name: 'app-feedback-collection',
    component: () => import('@/pages/app/feedback-entry/collection/index.vue'),
    meta: {
      subject: 'feedback.feedback',
      action: 'create',
      navActiveLink: 'feedback-entry',
    },
  },
  {
    path: '/invalid-url/:code?/:title?/:description?',
    name: 'invalid-url',
    component: () => import('@/views/misc/invalid-url.vue'),
    meta: {
      layout: 'client', 
      public: true, 
    },
    props: true, 
  },
  {
    path: '/app/escalation/:departmentId/:categoryId?',
    name: 'app-escalation-create-update',
    component: () => import('@/pages/app/escalation/create-update.vue'),
    meta: {
      navActiveLink: 'users',
    },
    props: true, 
  },
]

