<script setup>
import FilterComponent from '@/@core/components/custom/FilterComponent.vue'
import AnalyticsFeedbackTracker from '@/@core/components/feedback/AnalyticsFeedbackTracker.vue'
import feedbackService from '@/services/feedback.service'
import CustomerCommunicationFeedbackTable from '@/views/feedback/feedback-panel/CustomerCommunicationFeedbackTable.vue'
import FeedbackDrawer from '@/views/feedback/feedback-panel/drawer/FeedbackDrawer.vue'
import FeedbackResponseDrawer from '@/views/feedback/feedback-panel/drawer/FeedbackResponseDrawer.vue'



definePage({
  meta: {
    name: 'feedback-panel',
    subject: 'feedback.feedback',
    action: 'read',

  },  
})

const feedbackFilters = ref({})
const { data: filterOptions } = await useApi(createUrl('/feedbacks/filters', {
  query: {
    type: 'customer_communication',
  },
}))

const isFeedbackDrawerVisible = ref(false)
const isFeedbackResponseDrawerVisible = ref(false)
const feedbackDrawerData = ref()
const feedbackResponseDrawerData = ref()
const feedbackDrawerType = ref('View')

const isCommunicationDialogVisible = ref(false)
const communicationDrawerData = ref()
const communicationDrawerType = ref('View')

const showFeedback = (feedbackData, type) => {
  feedbackDrawerData.value = { ...feedbackData, drawerType: 'customer-communication' }
  feedbackDrawerType.value = type
  isFeedbackDrawerVisible.value = true
}

const showFeedbackResponse = feedbackData => {
  feedbackResponseDrawerData.value = feedbackData
  isFeedbackResponseDrawerVisible.value = true
}


const feedbackTable = ref(null)
const feedbackTracker = ref(null)


const saveCustomerCommunication =  communication => {
  if( feedbackResponseDrawerData.value?.id) {
    communication.feedback_id = feedbackResponseDrawerData.value.id
  } else {
    communication.feedback_id = feedbackDrawerData.value.id
  }
  if(communication?.feedback_id) {
    feedbackService.saveCustomerCommunication(communication, data=> {
      isCommunicationDialogVisible.value = false
      feedbackTable.value?.fetchFeedbacks()
      feedbackTracker.value?.fetchStatusGraph()
      isFeedbackDrawerVisible.value = false
    })
  } 
}

const refreshCustomerCommunicationFeedbackTable = () => {
  feedbackTable.value?.fetchFeedbacks()
  feedbackTracker.value?.fetchStatusGraph()
}
</script>

<template>
  <section>
    <VRow class="match-height mb-6">
      <VCol
        cols="12"
        md="6"
      >
        <FilterComponent
          v-model:filters="feedbackFilters"
          :filters-options="filterOptions"
        />
      </VCol>
      <VCol
        cols="12"
        md="6"
      >
        <AnalyticsFeedbackTracker
          ref="feedbackTracker"
          class="h-100" 
          :feedback-filters="feedbackFilters"
          :customer-communication="1"
        />
      </VCol>
    </VRow>
    <CustomerCommunicationFeedbackTable
      ref="feedbackTable"
      :feedback-filters="feedbackFilters"
      @show-feedback="showFeedback"
      @show-feedback-response="showFeedbackResponse"
    />
  
    <FeedbackResponseDrawer
      v-model:isDrawerOpen="isFeedbackResponseDrawerVisible"
      :feedback-data="feedbackResponseDrawerData"
    />
  
    <FeedbackDrawer
      v-model:isDrawerOpen="isFeedbackDrawerVisible"
      :feedback-data="feedbackDrawerData"
      :customer-communication="1"
      @show-feedback-response="showFeedbackResponse"
      @update:type="feedbackDrawerType = $event"
      @refresh-feedback-table="refreshCustomerCommunicationFeedbackTable"
      @show-communication-dialog="isCommunicationDialogVisible = true"
    />


    <CustomerCommunicationDialog
      v-model:isDialogVisible="isCommunicationDialogVisible"
      @submit="saveCustomerCommunication"
    />
  </section>
</template>
