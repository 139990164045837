<script setup>
import { cookieRef } from '@/@layouts/stores/config'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import locationService from '@/services/location.service'
import LocationDrawer from '@/views/masters/location/drawer/LocationDrawer.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

const searchQuery = ref('')
const isConfirmDialogVisible = ref(false)
const selectLocationType =ref(1)

definePage({
  meta: {
    name: 'master-location',
    subject: 'master.location',
    action: 'read',
  },  
})

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const errors = ref({
  name: [],
  code: [],
  status: [],
})

const onError = error => {
  if (error.data){
    
    errors.value = error.data.errors
  }
}

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order.toUpperCase()
}

// Headers
const headers = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Code',
    key: 'code',
  },
  {
    title: 'Parent Location',
    key: 'parent.name',
    sortable: false,
  },
  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Unit',
    key: 'unit.name',
    sortable: false,
  },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]


const { data: locationTypes } = await useApi(createUrl('/masters/location-types', {
  query: {
    search: {
      unit_id: cookieRef('selectedUnit'),
    },
  },
}))

const {
  data: locationsData,
  execute: fetchLocations,
} =  await useApi(createUrl('/masters/locations', {
  query: {
    paged: 1,
    q: searchQuery,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
    search: {
      location_type_id: selectLocationType,
      unit_id: cookieRef('selectedUnit'),
    },
  },
}))


const locations = computed(() => locationsData.value?.data)
const totalLocations = computed(() => locationsData.value?.meta?.total)

const updateLocation = data => {
 
  if (data.id){
    locationService.updateLocation(data.id, data, ()=>
    {
      fetchLocations()
      isLocationDrawerVisible.value = false
      resetErrors()
    }, onError)
   
  }
}

const saveLocation = data => {
  locationService.addLocation(data, ()=>
  {
    fetchLocations()
    isLocationDrawerVisible.value = false
    resetErrors()

  }, onError)
}

const resetErrors = () => {
  errors.value = {
    name: [],
    code: [],
    status: [],
  }
}

const isLocationDrawerVisible = ref(false)
const locationDrawerData = ref()
const locationDeleteData = ref()
const locationDrawerType = ref('Add')

const showLocation = (locationData, type) => {
  locationDrawerData.value = locationData
  locationDrawerType.value = type  
  isLocationDrawerVisible.value = true
}

const deleteLocation = async (confirm, data) => {
  if(data?.id && confirm ){
    await locationService.deleteLocation(data.id, () => {
      fetchLocations()
      isConfirmDialogVisible.value = false
    }, onError)
  }
}

const showConfirmDialog = locationData => {
  locationDeleteData.value = locationData
  isConfirmDialogVisible.value = true
}

watch(isConfirmDialogVisible, () => {
  if (!isConfirmDialogVisible.value){
    locationDeleteData.value = {}
  }
})
</script>

<template>
  <section>
    <VCard>
      <VItemGroup
        v-model="selectLocationType"
        selected-class="bg-primary "
        mandatory
      >
        <VContainer>
          <VRow>
            <VCol
              v-for="locationType in locationTypes"
              :key="locationType.id"
              cols="12"
              md="2"
            >
              <VItem
                v-slot="{isSelected, selectedClass, toggle }"
                :value="locationType.id"
              >
                <VCard
                  class="d-flex align-center cursor-pointer"
                  :class="[selectedClass]"
                  height="100"
                  dark
                  @click="toggle"
                >
                  <div
                    class="text-h3 flex-grow-1 text-center"
                    :class="isSelected ? 'text-white' : 'text-primary'"
                  >
                    {{ locationType.name }}
                  </div>
                </VCard>
              </VItem>
            </VCol>
          </VRow>
        </VContainer>
      </VItemGroup>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

       
          <VBtn             
            v-if="$can('create', 'master.location')"
            prepend-icon="tabler-plus"
            @click="showLocation({},'Add')"
          >
            Add New Location
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="locations ?? []"
        :items-length="totalLocations??0"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :locationData="item"
            @click="showLocation(item,'View')"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg
                v-if="item.avatar"
                :src="item.avatar"
              />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>
        <template #item.code="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.code }}</span>
        </template>
        <template #item.status="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.status?'Active':"Inactive" }}</span>
        </template>
        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn             
            v-if="$can('delete', 'master.location')"
            @click="showConfirmDialog(item)"
          >
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <IconBtn             
            v-if="$can('update', 'master.location')"
            @click="showLocation(item,'Edit')"
          >
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <!--
          <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
          <p class="text-sm text-disabled mb-0">
          {{ paginationMeta({ page, itemsPerPage }, totalLocations) }}
          </p>

          <VPagination
          v-model="page"
          :length="Math.ceil(totalLocations / itemsPerPage)"
          :total-visible="$vuetify.display.xs ? 1 : Math.ceil(totalLocations / itemsPerPage)"
          >
          <template #prev="slotProps">
          <VBtn
          variant="tonal"
          color="default"
          v-bind="slotProps"
          :icon="false"
          >
          Previous
          </VBtn>
          </template>

          <template #next="slotProps">
          <VBtn
          variant="tonal"
          color="default"
          v-bind="slotProps"
          :icon="false"
          >
          Next
          </VBtn>
          </template>
          </VPagination>
          </div>
          </template> 
        -->
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>  
    <LocationDrawer
      v-model:isDrawerOpen="isLocationDrawerVisible"
      :location-data="locationDrawerData"
      :type="locationDrawerType ?? 'Add'"
      :errors="errors"
      @update:type="locationDrawerType = $event"
      @update:location-data="updateLocation"
      @save-location="saveLocation"
    />
    <ConfirmDialog
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="Location Deleted."
      confirm-title="Deleted!"
      :data="locationDeleteData"
      @confirm="deleteLocation"
    />
  </section>
</template>
