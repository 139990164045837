<script setup>
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import questionService from "@/services/question.service";
import { VDataTableServer, VDataTable } from "vuetify/labs/VDataTable";

const searchQuery = ref("");
const isConfirmDialogVisible = ref(false);
const selectedFormType = ref(1);

definePage({
  meta: {
    name: "questionnaire-question",
    subject: "master.question",
    action: "read",
  },
});

// Data table options
const itemsPerPage = ref(10);
const page = ref(1);
const sortBy = ref();
const orderBy = ref();

const errors = ref({
  name: [],
  code: [],
  status: [],
});

const onError = (error) => {
  if (error.data) {
    errors.value = error.data.errors;
  }
};

const updateOptions = (options) => {
  page.value = options.page;
  orderBy.value = options.sortBy[0]?.key;
  sortBy.value = options.sortBy[0]?.order.toUpperCase();
};

const selectedQuestionGroup = ref();

// Headers
const headers = [
  { title: "", key: "data-table-expand" },
  {
    title: "Label",
    key: "label",
  },
  {
    title: "Order",
    key: "order",
  },
  {
    title: "Question Type",
    key: "question_type",
  },
  {
    title: "Question Key",
    key: "question_key",
  },
  {
    title: "Question Group",
    key: "question_group.name",
    sortable: false,
  },
  {
    title: "Status",
    key: "status",
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
];

const subQuestionsHeader = [
  {
    title: "Label",
    key: "label",
  },
  {
    title: "Order",
    key: "order",
  },
  {
    title: "Question Type",
    key: "question_type",
  },
  {
    title: "Question Key",
    key: "question_key",
  },
  // {
  //   title: 'Question Group',
  //   key: 'question_group.name',
  //   sortable: false,
  // },
  {
    title: "Status",
    key: "status",
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
];

const { data: formTypes } = await useApi(
  createUrl("masters/form-types", {
    query: {
      select: ["id", "name"],

      // search: {
      //   unit_id: cookieRef('selectedUnit'),
      // },
    },
  })
);

const { isFetching: isFetchingQuestionGroup, data: questionGroups } =
  useListFetchingService(
    createUrl("/masters/question-groups", {
      query: {
        form_type_id: selectedFormType,
      },
    })
  );

const { data: questionsData, execute: fetchQuestions } = useApi(
  createUrl("/masters/questions", {
    query: {
      paged: 1,
      showAll: true,
      search: {
        label: searchQuery,
        question_group_id: selectedQuestionGroup,
      },
      itemsPerPage,
      page,
      sort: sortBy,
      order_by: orderBy,
      form_type_id: selectedFormType,
    },
  })
);

const questions = computed(
  () => questionsData.value?.data ?? questionsData.value
);
const totalQuestions = computed(
  () => questionsData.value?.meta?.total ?? questionsData.value?.length
);

const isQuestionDrawerVisible = ref(false);
const questionDrawerData = ref();
const questionDeleteData = ref();
const questionDrawerType = ref("Add");

const showQuestion = (questionData, type) => {
  questionDrawerData.value = questionData;
  questionDrawerType.value = type;
  isQuestionDrawerVisible.value = true;
};

const deleteQuestion = async (confirm, data) => {
  if (data?.id && confirm) {
    await questionService.deleteQuestion(
      data.id,
      () => {
        fetchQuestions();
        isConfirmDialogVisible.value = false;
      },
      onError
    );
  }
};

const showConfirmDialog = (questionData) => {
  questionDeleteData.value = questionData;
  isConfirmDialogVisible.value = true;
};

watch(isConfirmDialogVisible, () => {
  if (!isConfirmDialogVisible.value) {
    questionDeleteData.value = {};
  }
});

const getClass = (item) => {
  return item?.calories > 500
    ? "cal-high"
    : item?.calories > 400
    ? "cal-medium"
    : "cal-low";
};
</script>

<template>
  <section>
    <VCard>
      <VItemGroup
        v-model="selectedFormType"
        selected-class="bg-primary "
        mandatory
        @update:model-value="selectedQuestionGroup = null"
      >
        <VContainer>
          <VRow>
            <VCol
              v-for="formType in formTypes"
              :key="formType.id"
              cols="12"
              md="2"
            >
              <VItem
                v-slot="{ isSelected, selectedClass, toggle }"
                :value="formType.id"
              >
                <VCard
                  class="d-flex align-center cursor-pointer"
                  :class="[selectedClass]"
                  height="100"
                  dark
                  @click="toggle"
                >
                  <div
                    class="text-h3 flex-grow-1 text-center"
                    :class="isSelected ? 'text-white' : 'text-primary'"
                  >
                    {{ formType.name }}
                  </div>
                </VCard>
              </VItem>
            </VCol>
          </VRow>
        </VContainer>
      </VItemGroup>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 20rem">
            <AppSelect
              v-model="selectedQuestionGroup"
              :loading="isFetchingQuestionGroup"
              :items="questionGroups"
              :item-title="(item) => item.name"
              :item-value="(item) => item.id"
              clearable
              placeholder="Filter Question Group"
            />
          </div>

          <div style="inline-size: 20rem">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

          <RouterLink
            v-if="$can('create', 'master.question')"
            :to="{ name: 'app-question-add' }"
            class="font-weight-medium text-link"
          >
            <VBtn prepend-icon="tabler-plus"> Add New Question </VBtn>
          </RouterLink>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="questions ?? []"
        :items-length="totalQuestions ?? 0"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #expanded-row="slotProps">
          <tr class="!text-normal">
            <td colspan="12" class="level-bg-color px-6 py-6 overflow-hidden">
              <v-card variant="outlined w-100" class="table-container">
                <VDataTable
                  :items="slotProps.item?.sub_questions ?? []"
                  :headers="subQuestionsHeader"
                  :items-per-page="-1"
                  hide-default-footer
                  disable-sort
                  class="custom-data-table text-no-wrap overflow-auto"
                >
                  <template #item.label="{ item }">
                    <VMenu open-on-hover :close-on-content-click="false">
                      <template #activator="{ props }">
                        <span v-bind="props">
                          {{
                            item.label?.substring(0, 25) +
                              (item.label?.length > 25 ? "..." : "") ?? "NA"
                          }}
                        </span>
                      </template>
                      <VCard>
                        <VList>
                          <VListItem class="mx-0">
                            <template #title>
                              <VListItemTitle
                                class="text-h6 text-primary text-capitalize"
                              >
                                {{ item.label ?? "NA" }}
                              </VListItemTitle>
                            </template>
                          </VListItem>
                        </VList>
                      </VCard>
                    </VMenu>
                  </template>
                  <template #item.name="{ item }">
                    <div
                      class="d-flex align-center cursor-pointer"
                      :questionData="item"
                      @click="showQuestion(item, 'View')"
                    >
                      <VAvatar
                        size="34"
                        :variant="!item.avatar ? 'tonal' : undefined"
                        class="me-3"
                      >
                        <VImg v-if="item.avatar" :src="item.avatar" />
                        <span v-else>{{ avatarText(item.name) }}</span>
                      </VAvatar>
                      <div class="d-flex flex-column">
                        <h6 class="text-base">
                          {{ item.name }}
                        </h6>
                      </div>
                    </div>
                  </template>
                  <template #item.total_questions="{ item }">
                    <span class="text-capitalize font-weight-medium">{{
                      item.questions?.length
                    }}</span>
                  </template>
                  <template #item.status="{ item }">
                    <span class="text-capitalize font-weight-medium">{{
                      item.status ? "Active" : "Inactive"
                    }}</span>
                  </template>
                  <template #item.actions="{ item }">
                    <IconBtn
                      v-if="$can('delete', 'master.question')"
                      @click="showConfirmDialog(item)"
                    >
                      <VIcon icon="tabler-trash" />
                    </IconBtn>

                    <RouterLink
                      v-if="$can('update', 'master.question')"
                      :to="{
                        name: 'app-question-tab',
                        params: { id: item.id },
                      }"
                      class="font-weight-medium text-link"
                    >
                      <VIcon icon="tabler-edit" />
                    </RouterLink>
                  </template>
                </VDataTable>
              </v-card>
            </td>
          </tr>
        </template>

        <template #item.label="{ item }">
          <VMenu open-on-hover :close-on-content-click="false">
            <template #activator="{ props }">
              <span v-bind="props">
                {{
                  item.label?.substring(0, 25) +
                    (item.label?.length > 25 ? "..." : "") ?? "NA"
                }}
              </span>
            </template>
            <VCard>
              <VList>
                <VListItem class="mx-0">
                  <template #title>
                    <VListItemTitle
                      class="text-h6 text-primary text-capitalize"
                    >
                      {{ item.label ?? "NA" }}
                    </VListItemTitle>
                  </template>
                </VListItem>
              </VList>
            </VCard>
          </VMenu>
        </template>

        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :questionData="item"
            @click="showQuestion(item, 'View')"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg v-if="item.avatar" :src="item.avatar" />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>
        <template #item.total_questions="{ item }">
          <span class="text-capitalize font-weight-medium">{{
            item.questions?.length
          }}</span>
        </template>
        <template #item.status="{ item }">
          <span class="text-capitalize font-weight-medium">{{
            item.status ? "Active" : "Inactive"
          }}</span>
        </template>

        <template #item.actions="{ item }">
          <IconBtn
            v-if="$can('delete', 'master.question')"
            @click="showConfirmDialog(item)"
          >
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <RouterLink
            v-if="$can('update', 'master.question')"
            :to="{ name: 'app-question-tab', params: { id: item.id } }"
            class="font-weight-medium text-link"
          >
            <VIcon icon="tabler-edit" />
          </RouterLink>
        </template>
      </VDataTableServer>
    </VCard>
    <!--
      <QuestionDrawer
      v-model:isDrawerOpen="isQuestionDrawerVisible"
      :question-group-data="questionDrawerData"
      :type="questionDrawerType ?? 'Add'"
      :errors="errors"
      @update:type="questionDrawerType = $event"
      @update:question-group-data="updateQuestion"
      @save-question-group="saveQuestion"
      /> 
    -->
    <ConfirmDialog
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="Question Deleted."
      confirm-title="Deleted!"
      :data="questionDeleteData"
      @confirm="deleteQuestion"
    />
  </section>
</template>

<style>
.level-bg-color {
  background-color: #f7f7f7;
}

.non-selectable {
  user-select: none !important;
}

/* stylelint-disable-next-line no-descending-specificity */
.custom-data-table .v-data-table-footer {
  display: none !important;
}
</style>
