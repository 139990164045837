<script setup>
import TimePicker from '@/@core/components/TimePicker.vue'
import { cookieRef } from '@/@layouts/stores/config'

definePage({
  meta: {
    name: 'escalation-create-update',
    subject: 'configuration.escalation',
    action: 'update',
    navActiveLink: 'escalation',
  },  
})

const route = useRoute('escalation-create-update')
const router = useRouter()
const snackbar = useToast()

const isUpdate = computed(() => {
  return !isNaN(route.params.departmentId) ? true : false
})

const escalationId = computed(() => null)

const escalationData = ref()

const escalationTypes = await getEnumValue('EscalationTypes', true)

const selectedUnit = computed(()=> cookieRef('selectedUnit'))

const selectedDepartment = computed(()=>{
  return escalationData.value?.department?.id ?? escalationData.value?.department
})

const selectedCategory = computed(()=>{
  return escalationData.value?.category?.id ?? escalationData.value?.category
})

const { isFetching: departmentLoading, data: departments } = await useApi(createUrl('/masters/departments'))

const { isFetching: categoriesLoading,  data: categories } = useApi(createUrl('/masters/categories', {
  query: {
    search: {
      department_id: selectedDepartment,
    },
  },
}, {
  beforeFetch: ({ url, options, cancel }) => {
    if (!(selectedDepartment.value)) cancel()
  },
},
))

const { isFetching: escalationLevelLoading, data: escalationLevels } =await useApi(createUrl('/configurations/escalation-level'))
const { isFetching: unitLoading, data: units } =await  useApi(createUrl('/masters/units'))

const { isFetching: userLoading, data: users } = useListFetchingService('/masters/users', {
  query: {
    unit_id: selectedUnit,
  },
}, {
  beforeFetch: ({ url, options, cancel }) =>{
    if (!(selectedUnit.value)) cancel()
  },
})

const feedLoadedData = data => {

  let filteredEscalations
  if (escalationData.value.category) {
    filteredEscalations = data.escalations
  } else {
    filteredEscalations = data.escalations.filter(x => x.category === null)
  }

  const levels = escalationLevels.value.map(escalationLevel => {
    const exist = filteredEscalations.find(x => x.escalation_level_id === escalationLevel.id)
    
    return {
      escalation_level_id: exist ? exist.escalation_level_id : escalationLevel.id,

      // escalation_type: exist ? exist.escalation_type : escalationLevel.escalation_type,
      level: exist ? exist.escalation_level.level : escalationLevel.level,
      escalation_time: exist ? exist.escalation_level.escalation_time : null,
      remarks: exist ? exist.remarks : null,
      users: exist ? exist.escalation_users.map(x => x.id) : [],
    }
  })


  if (escalationId.value) {
    escalationData.value = {
      unit: selectedUnit.value,

      // unit: filteredEscalations[0]?.unit_id,
      department: filteredEscalations[0]?.department_id,
      category: filteredEscalations[0]?.category_id,
      escalation_time: filteredEscalations[0]?.escalation_time,
      escalation_levels: levels,
    }
  } else {
    escalationData.value.unit = selectedUnit.value
    escalationData.value.escalation_time = filteredEscalations[0]?.escalation_time
    escalationData.value.escalation_levels = levels
  }
}

const loadDefaultData = async () => {
  let levels = []
  escalationLevels.value.forEach(escalationLevel => {
    levels.push({
      escalation_level_id: escalationLevel.id,

      // escalation_type: escalationLevel.escalation_type,
      level: escalationLevel.level,
      remarks: null,
      users: [],
    })
  })

  escalationData.value = {
    unit: selectedUnit.value,
    department: null,
    category: null,
    escalation_time: null,
    escalation_levels: levels,
  }
}

const loadEscalationByDepartment = async (depart, cata = null) => {

  const {
    data: apiData,
  } =  await useApi(createUrl('/configurations/escalations', {
    query: {
      paged: 1,
      search: {
        id: depart,
      },
      category_id: cata,
      itemsPerPage: 1,
    },
  }))

  feedLoadedData(apiData.value.data[0])
    
}

// check if escalation id is present is integer
// if (escalationId.value === parseInt(escalationId.value) && escalationId.value > 0){

//   const { data } = await useApi(createUrl(`/configurations/escalations/${escalationId.value}`))

//   feedLoadedData(data.value)

// } else {

// }

// if (isUpdate) {
//   // escalationData.value = {
//   //   department: route.params.departmentId,
//   //   category: route.params.categoryId,
//   // }
//   await loadEscalationByDepartment(route.params.departmentId, route.params.categoryId)
// } else {
//   loadDefaultData()
// }

watch(selectedDepartment, (_new, _old) => {
  if (_new) loadEscalationByDepartment(_new)
})

watch(selectedCategory, (_new, _old) => {
  if (_new) loadEscalationByDepartment(selectedDepartment.value, _new)
})

const saveEscalation = async () => {

  let formData = []

  escalationData.value.escalation_levels.forEach(escalation => {
    if (escalation.users.length > 0) {
      formData.push({
        escalation_level_id: escalation.escalation_level_id,

        // department_id: escalationData.value.department.id,
        // escalation_type: escalation.escalation_type,
        remarks: escalation.remarks,
        users: escalation.users,
      })
    }
  })

  await $api('/configurations/escalations', {
    method: 'POST',
    body: {
      unit_id: escalationData.value.unit,
      department_id: escalationData.value.department,
      category_id: escalationData.value.category,

      // escalation_type: "TICKET",
      escalations: formData,
      escalation_time: escalationData.value.escalation_time,
    },
  }).then(({ data }) => {
    // alert('Escalation created successfully')
    snackbar.success(`Escalation ${isUpdate.value ? 'updated' : 'created'} successfully`, 'success')
  }).catch(error => {
    // alert('ERROR')
  })

}

onBeforeMount(() => {

  if (isUpdate.value) {
    escalationData.value = {
      department: parseInt(route.params.departmentId),
      category: route.params.categoryId && !isNaN(route.params.categoryId) ? parseInt(route.params.categoryId) : null,
    }
  } else {
    loadDefaultData()
  }

})
</script>

<template>
  <div>
    <VCard>
      <!-- <VCardTitle></VCardTitle> -->
      <VCardItem>
        <VCol cols="12">
          <VForm>
            <VRow>
              <!--
                <VCol
                v-if="$auth?.user?.units?.length > 1"
                cols="12"
                md="4"
                lg="3"
                >
                <AppSelect
                v-model="escalationData.unit"
                label="Units"
                :items="units"
                :loading="unitLoading"
                :item-title="item => item.name"
                :item-value="item => item.id"
                persistent-hint
                :rules="[requiredValidator]"
                required
                placeholder="Select unit"
                />
                </VCol> 
              -->
    
              <VCol
                cols="12"
                md="4"
                lg="3"
              >
                <AppCombobox
                  v-model="escalationData.department"
                  label="Department"
                  :items="departments"
                  :loading="departmentLoading"
                  :item-title="item => item.name"
                  :item-value="item => item.id"
                  :rules="[requiredValidator]"
                  :return-object="false"
                  required
                  placeholder="Select Department"
                />
              </VCol>

              <VCol
                cols="12"
                md="4"
                lg="3"
              >
                <AppCombobox
                  v-model="escalationData.category"
                  label="Category"
                  :items="categories ?? []"
                  :loading="categoriesLoading"
                  :item-title="item => item.name"
                  :item-value="item => item.id"
                  :return-object="false"
                  clearable
                  placeholder="Select Category"
                  :disabled="!escalationData.department"
                />
              </VCol>

              <VCol
                cols="12"
                md="4"
                lg="3"
              >
                <TimePicker
                  v-model="escalationData.escalation_time"
                  label="Time (DD:HH:MM)"
                  return-type="minute"
                />
              </VCol>
            </VRow>

            <VRow
              v-for="(escalationLevel, index) in escalationData.escalation_levels"
              :key="index"
            >
              <!--
                <VCol
                cols="12"
                md="4"
                lg="3"
                >
                {{ escalationLevel.level }}
                </VCol> 
              -->
              <VCol
                cols="12"
                md="4"
                lg="3"
              >
                <AppTextField
                  v-model="escalationData.escalation_levels[index].escalation_level_id"
                  label="Level"
                  placeholder="Enter Remark"
                  disabled="true"
                />
              </VCol>
              <VCol
                cols="12"
                md="4"
                lg="3"
              >
                <AppSelect
                  v-model="escalationData.escalation_levels[index].users"
                  label="Users"
                  :items="users"
                  :loading="userLoading"
                  :item-title="item => item.name"
                  :item-value="item => item.id"
                  persistent-hint
                  multiple
                  placeholder="Select Users"
                />
              </VCol>
              <VCol
                cols="12"
                md="4"
                lg="3"
              >
                <AppTextField
                  v-model="escalationData.escalation_levels[index].remarks"
                  label="Remark"
                  placeholder="Enter Remark"
                />
              </VCol>
            </VRow>

            <VRow>
              <VCol cols="12">
                <VBtn
                  type="submit"
                  class="me-3"
                  :loading="false"
                  @click.prevent="saveEscalation"
                >
                  {{ isUpdate ? 'Update' : 'Create' }}
                </VBtn>
               
                <VBtn
                  type="reset"
                  variant="outlined"
                  color="secondary"
                  @click="closeNavigationDrawer"
                >
                  Cancel
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCol>
      </VCardItem>
    </VCard>
  </div>
  <!--
    <div v-else>
    <VRow>
    <VCol cols="12">
    <VCard title="Escalation Not Found">
    <VCardText>
    <p>Escalation with ID: {{ route.params.id }} not found</p>
    </VCardText>
    <VCardActions>
    <VButton
    color="primary"
    @click="router.push({ name: 'escalation' })"
    >
    Go Back
    </VButton>
    </VCardActions>
    </VCard>
    </VCol>
    </VRow>
    </div> 
  -->
</template>
