<script setup>
import QuestionSettings from '@/views/pages/question/QuestionSettings.vue'

const props = defineProps({
  id: {
    type: String,
    default: null,
    required: false,
  },

})

definePage({})

const route = useRoute('users')
const router = useRouter()
const questionData = ref({})


if (route.params?.id) {
  const { data } = await useApi(`/masters/questions/${ route.params?.id }`)

  questionData.value = toRaw(data.value)
}
</script>

<template>
  <div>
    <div v-if="route.params?.id">
      <QuestionSettings :question-data="questionData" />
    </div>
    <div v-else-if="!route.params?.id">
      <QuestionSettings :question-data="null" />
    </div>
    <VCard v-else>
      <VCardTitle class="text-center">
        <VRow align="center">
          <VCol>
            <VIcon
              icon="tabler-alert-triangle"
              size="24"
            />
            User Not Found
          </VCol>
        </VRow>
        <!-- go back -->
        <VBtn
          color="primary"
          class="mt-3"
          @click="router.go(-1)"
        >
          Go Back
        </VBtn>
      </VCardTitle>
    </VCard>
  </div>
</template>
