<script setup>
import FilterComponent from '@/@core/components/custom/FilterComponent.vue'
import AnalyticsFeedbackTracker from '@/@core/components/feedback/AnalyticsFeedbackTracker.vue'
import FeedbackTable from '@/views/feedback/feedback-panel/FeedbackTable.vue'
import FeedbackDrawer from '@/views/feedback/feedback-panel/drawer/FeedbackDrawer.vue'
import FeedbackResponseDrawer from '@/views/feedback/feedback-panel/drawer/FeedbackResponseDrawer.vue'
import CustomerCommunicationDrawer from '@/views/feedback/feedback-panel/drawer/customer/CustomerCommunicationDrawer.vue'



definePage({
  meta: {
    name: 'feedback-panel',
    subject: 'feedback.feedback',
    action: 'read',

  },  
})

const feedbackFilters = ref({})
const { data: filterOptions } = await useApi(createUrl('/feedbacks/filters'))

const isFeedbackDrawerVisible = ref(false)
const isFeedbackResponseDrawerVisible = ref(false)
const feedbackDrawerData = ref()
const feedbackResponseDrawerData = ref()
const feedbackDrawerType = ref('View')

const isCommunicationDrawerVisible = ref(false)
const communicationDrawerData = ref()
const communicationDrawerType = ref('View')

const showFeedback = (feedbackData, type) => {
  feedbackDrawerData.value = feedbackData
  feedbackDrawerType.value = type  
  isFeedbackDrawerVisible.value = true
}

const showFeedbackResponse = feedbackData => {
  feedbackResponseDrawerData.value = feedbackData
  isFeedbackResponseDrawerVisible.value = true
}

const showCommunicationDrawer= (feedbackData, type) => {
  communicationDrawerData.value = feedbackData
  communicationDrawerType.value = type
  isCommunicationDrawerVisible.value = true
}

const feedbackTable = ref(null)
const feedbackTracker = ref(null)

const refreshFeedbackTable = () => {
 
 
  feedbackTable.value?.fetchFeedbacks()
  feedbackTracker.value?.fetchStatusGraph()
}
</script>

<template>
  <section>
    <VRow class="match-height mb-6">
      <VCol
        cols="12"
        md="6"
      >
        <FilterComponent
          v-model:filters="feedbackFilters"
          :filters-options="filterOptions"
        />
      </VCol>
      <VCol
        cols="12"
        md="6"
      >
        <AnalyticsFeedbackTracker
          ref="feedbackTracker"
          class="h-100" 
          :feedback-filters="feedbackFilters"
        />
      </VCol>
    </VRow>
    <FeedbackTable
      ref="feedbackTable"
      :feedback-filters="feedbackFilters"
      @show-feedback="showFeedback"
      @show-feedback-response="showFeedbackResponse"
      @show-communication-drawer="showCommunicationDrawer"
    />
  
    <FeedbackResponseDrawer
      v-model:isDrawerOpen="isFeedbackResponseDrawerVisible"
      :feedback-data="feedbackResponseDrawerData"

      @show-communication-drawer="showCommunicationDrawer"
    />
    <CustomerCommunicationDrawer
      v-model:isDrawerOpen="isCommunicationDrawerVisible"
      :feedback-data="communicationDrawerData"
      :active-tab="communicationDrawerType"
    />
    <FeedbackDrawer
      v-model:isDrawerOpen="isFeedbackDrawerVisible"
      :feedback-data="feedbackDrawerData"
      @show-feedback-response="showFeedbackResponse"
      @show-communication-drawer="showCommunicationDrawer"
      @update:type="feedbackDrawerType = $event"
      @refresh-feedback-table="refreshFeedbackTable"
    />
  </section>
</template>
