<template>
  <div>
    <VCard title="Feedback Analysis 🙌">
      <VCardText>Coming Soon</VCardText>
    </VCard>
  </div>
</template>

<script setup>
definePage({
  meta: {
    name: 'feedback-dashboard',
  },  
})
</script>
