import { abilitiesPlugin } from '@casl/vue'
import { userAuthState } from '@core/auth'
import { ability } from './ability'

export default function (app) {
  const userAbilityRules = userAuthState().userAbilities
  const initialAbility = ability.update(userAbilityRules??[])

  app.use(abilitiesPlugin, initialAbility, {
    useGlobalProperties: true,
  })
}
