<script setup>
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import categoryService from '@/services/category.service'
import CategoryDrawer from '@/views/masters/category/drawer/CategoryDrawer.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

const searchQuery = ref('')
const isConfirmDialogVisible = ref(false)

definePage({
  meta: {
    name: 'master-category',
    subject: 'master.category',
    action: 'read',
  },  
})

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const errors = ref({
  name: [],
  code: [],
  status: [],
})

const onError = error => {
  if (error.data){
   
    errors.value = error.data.errors
  }
}

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order.toUpperCase()
}

// Headers
const headers = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Code',
    key: 'code',
  },
  {
    title: 'Department',
    key: 'department_id',
  },
  {
    title: 'Parent Category',
    key: 'parent_id',
  },
  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]

const {
  data: categoriesData,
  execute: fetchCategories,
} =   useApi(createUrl('/masters/categories', {
  query: {
    paged: 1,
    q: searchQuery,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
  },
}))

const categories = computed(() => categoriesData.value?.data)
const totalCategories = computed(() => categoriesData.value?.meta?.total)

const updateCategory = data => {
  if (data.id){
    categoryService.updateCategory(data.id, data, ()=>
    {
      fetchCategories()
      isCategoryDrawerVisible.value = false
      resetErrors()
    }, onError)
   
  }
}

const saveCategory = data => {
  categoryService.addCategory(data, ()=>
  {
    fetchCategories()
    isCategoryDrawerVisible.value = false
    resetErrors()

  }, onError)
}

const resetErrors = () => {
  errors.value = {
    name: [],
    code: [],
    status: [],
  }
}

const isCategoryDrawerVisible = ref(false)
const categoryDrawerData = ref()
const categoryDeleteData = ref()
const categoryDrawerType = ref('Add')

const showCategory = (categoryData, type) => {
  categoryDrawerData.value = categoryData
  categoryDrawerType.value = type  
  isCategoryDrawerVisible.value = true
}

const deleteCategory = async (confirm, data) => {
  if(data?.id && confirm ){
    await categoryService.deleteCategory(data.id, () => {
      fetchCategories()
      isConfirmDialogVisible.value = false
    }, onError)
  }
}

const showConfirmDialog = categoryData => {
  categoryDeleteData.value = categoryData
  isConfirmDialogVisible.value = true
}

watch(isConfirmDialogVisible, () => {
  if (!isConfirmDialogVisible.value){
    categoryDeleteData.value = {}
  }
})
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

       
          <VBtn
            v-if="$can('create', 'master.category')"
            prepend-icon="tabler-plus"
            @click="showCategory({},'Add')"
          >
            Add New Category
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="categories"
        :items-length="totalCategories"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :categoryData="item"
            @click="showCategory(item,'View')"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg
                v-if="item.avatar"
                :src="item.avatar"
              />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>

        <template #item.parent_id="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.parent?.name }}</span>
        </template>

        <template #item.department_id="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.department?.name }}</span>
        </template>
        <template #item.code="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.code }}</span>
        </template>
        <template #item.status="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.status?'Active':"Inactive" }}</span>
        </template>
        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn             
            v-if="$can('delete', 'master.category')"
            @click="showConfirmDialog(item)"
          >
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <IconBtn             
            v-if="$can('update', 'master.category')"
            @click="showCategory(item,'Edit')"
          >
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>  
    <CategoryDrawer
      v-model:isDrawerOpen="isCategoryDrawerVisible"
      :category-data="categoryDrawerData"
      :type="categoryDrawerType ?? 'Add'"
      :errors="errors"
      @update:type="categoryDrawerType = $event"
      @update:category-data="updateCategory"
      @save-category="saveCategory"
    />
    <ConfirmDialog
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="Category Deleted."
      confirm-title="Deleted!"
      :data="categoryDeleteData"
      @confirm="deleteCategory"
    />
  </section>
</template>
