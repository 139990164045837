<script setup>
import { paginationMeta } from '@/@core/utils/paginationMeta'
import { cookieRef } from '@/@layouts/stores/config'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import questionGroupService from '@/services/questionGroup.service'
import QuestionGroupDrawer from '@/views/questionnaire/question-group/drawer/QuestionGroupDrawer.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

const searchQuery = ref('')
const isConfirmDialogVisible = ref(false)

definePage({
  meta: {
    name: 'questionnaire-group',
    subject: 'master.question_group',
    action: 'read',
  },  
})

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const errors = ref({
  name: [],
  code: [],
  status: [],
})

const onError = error => {
  if (error.data){
    
    errors.value = error.data.errors
  }
}

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order.toUpperCase()
}

// Headers
const headers = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Title',
    key: 'title',
  },
  {
    title: 'Order',
    key: 'order',
  },
  {
    title: 'Questions',
    key: 'total_questions',
    sortable: false,
  },
  {
    title: 'Form Type',
    key: 'form_type_id',
  },
  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]

const {
  data: questionGroupsData,
  execute: fetchQuestionGroups,
} =  await useApi(createUrl('/masters/question-groups', {
  query: {
    paged: 1,
    showAll: true,
    q: searchQuery,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
    search: {
      unit_id: cookieRef('selectedUnit'),
    },
  },
}))

const questionGroups = computed(() => questionGroupsData.value?.data)
const totalQuestionGroups = computed(() => questionGroupsData.value?.meta?.total)

const updateQuestionGroup = data => {
  if (data.id){
    questionGroupService.updateQuestionGroup(data.id, data, ()=>
    {
      fetchQuestionGroups()
      isQuestionGroupDrawerVisible.value = false
      resetErrors()
    }, onError)
   
  }
}

const saveQuestionGroup = data => {
  questionGroupService.addQuestionGroup(data, ()=>
  {
    fetchQuestionGroups()
    isQuestionGroupDrawerVisible.value = false
    resetErrors()

  }, onError)
}

const resetErrors = () => {
  errors.value = {
    name: [],
    code: [],
    status: [],
  }
}

const isQuestionGroupDrawerVisible = ref(false)
const questionGroupDrawerData = ref()
const questionGroupDeleteData = ref()
const questionGroupDrawerType = ref('Add')

const showQuestionGroup = (questionGroupData, type) => {
  questionGroupDrawerData.value = questionGroupData
  questionGroupDrawerType.value = type  
  isQuestionGroupDrawerVisible.value = true
}

const deleteQuestionGroup = async (confirm, data) => {
  if(data?.id && confirm ){
    await questionGroupService.deleteQuestionGroup(data.id, () => {
      fetchQuestionGroups()
      isConfirmDialogVisible.value = false
    }, onError)
  }
}

const showConfirmDialog = questionGroupData => {
  questionGroupDeleteData.value = questionGroupData
  isConfirmDialogVisible.value = true
}

watch(isConfirmDialogVisible, () => {
  if (!isConfirmDialogVisible.value){
    questionGroupDeleteData.value = {}
  }
})
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

       
          <VBtn
            v-if="$can('create', 'master.question_group')"
            prepend-icon="tabler-plus"
            @click="showQuestionGroup({},'Add')"
          >
            Add New Question Group
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="questionGroups"
        :items-length="totalQuestionGroups"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :questionGroupData="item"
            @click="showQuestionGroup(item,'View')"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg
                v-if="item.avatar"
                :src="item.avatar"
              />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>
        <template #item.form_type_id="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.form_type?.name }}</span>
        </template>
        <template #item.total_questions="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.questions?.length }}</span>
        </template>
        <template #item.status="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.status?'Active':"Inactive" }}</span>
        </template>
        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn 
            v-if="$can('delete', 'master.question_group')"
            @click="showConfirmDialog(item)"
          >
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <IconBtn 
            v-if="$can('update', 'master.question_group')"
            @click="showQuestionGroup(item,'Edit')"
          >
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, totalQuestionGroups) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(totalQuestionGroups / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(totalQuestionGroups / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>  
    <QuestionGroupDrawer
      v-model:isDrawerOpen="isQuestionGroupDrawerVisible"
      :question-group-data="questionGroupDrawerData"
      :type="questionGroupDrawerType ?? 'Add'"
      :errors="errors"
      @update:type="questionGroupDrawerType = $event"
      @update:question-group-data="updateQuestionGroup"
      @save-question-group="saveQuestionGroup"
    />
    <ConfirmDialog
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="QuestionGroup Deleted."
      confirm-title="Deleted!"
      :data="questionGroupDeleteData"
      @confirm="deleteQuestionGroup"
    />
  </section>
</template>
