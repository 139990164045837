<script setup>
import { paginationMeta } from '@/@core/utils/paginationMeta'
import { cookieRef } from '@/@layouts/stores/config'
import configService from '@/services/config.service'
import ConfigDrawer from '@/views/configurations/config/drawer/ConfigDrawer.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

const searchQuery = ref('')

definePage({
  meta: {
    name: 'master-config',
  },  
})

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const errors = ref({
  name: [],
  code: [],
  status: [],
})

const onError = error => {
  if (error.data){
   
    errors.value = error.data.errors
  }
}

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order.toUpperCase()
}

// Headers
const headers = [
  {
    title: 'Unit',
    key: 'unit.name',
  },
  {
    title: 'Key',
    key: 'key',
  },
  {
    title: 'Type',
    key: 'type',
  },
  
]


const {
  data: configsData,
  execute: fetchCategories,
} =  await useApi(createUrl('/configurations/configs', {
  query: {
    paged: 1,
    q: searchQuery,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
    search: {
      unit_id: cookieRef('selectedUnit'),

      // type: selectConfigType,
    },
  },
}))

const configs = computed(() => configsData.value?.data)
const totalCategories = computed(() => configsData.value?.meta?.total)

const updateConfig = data => {
  if (data.id){
    configService.updateConfig(data.id, data, ()=>
    {
      fetchCategories()
      isConfigDrawerVisible.value = false
      resetErrors()
    }, onError)
   
  }
}

const saveConfig = data => {
  configService.addConfig(data, ()=>
  {
    fetchCategories()
    isConfigDrawerVisible.value = false
    resetErrors()

  }, onError)
}

const resetErrors = () => {
  errors.value = {
    name: [],
    code: [],
    status: [],
  }
}

const isConfigDrawerVisible = ref(false)
const configDrawerData = ref()
const configDrawerType = ref('Add')

const showConfig = (configData, type) => {
  configDrawerData.value = configData
  configDrawerType.value = type  
  isConfigDrawerVisible.value = true
}
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!--        
            <VBtn
            prepend-icon="tabler-plus"
            @click="showConfig({},'Add')"
            >
            Add New Config
            </VBtn> 
          -->
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="configs"
        :items-length="totalCategories"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :configData="item"
            @click="showConfig(item,'View')"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg
                v-if="item.avatar"
                :src="item.avatar"
              />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>
        <template #item.code="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.code }}</span>
        </template>
        <template #item.status="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.status?'Active':"Inactive" }}</span>
        </template>
        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn @click="showConfig(item,'Edit')">
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, totalCategories) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(totalCategories / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(totalCategories / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>  
    <ConfigDrawer
      v-model:isDrawerOpen="isConfigDrawerVisible"
      :config-data="configDrawerData"
      :type="configDrawerType ?? 'Add'"
      :errors="errors"
      @update:type="configDrawerType = $event"
      @update:config-data="updateConfig"
      @save-config="saveConfig"
    />
  </section>
</template>
