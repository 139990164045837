<script setup>
import { paginationMeta } from '@/@core/utils/paginationMeta'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import formTypeService from '@/services/formType.service'
import FormTypeDrawer from '@/views/masters/formType/drawer/FormTypeDrawer.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

const searchQuery = ref('')
const isConfirmDialogVisible = ref(false)

definePage({
  meta: {
    name: 'master-form-type',
    subject: 'master.form_type',
    action: 'read',
  },  
})

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const errors = ref({
  name: [],
  code: [],
  status: [],
})

const onError = error => {
  if (error.data){
    errors.value = error.data.errors
  }
}

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order
}

// Headers
const headers = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Code',
    key: 'code',
  },

  // {
  //   title: 'Unit',
  //   key: 'unit.name',
  //   sortable: false,
  // },

  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]

const {
  data: formTypesData,
  execute: fetchFormTypes,
} =  await useApi(createUrl('/masters/form-types', {
  query: {
    paged: 1,
    q: searchQuery,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,

    // search: {
    //   unit_id: cookieRef('selectedUnit'),
    // },
  },
}))

const formTypes = computed(() => formTypesData.value?.data)
const totalFormTypes = computed(() => formTypesData.value?.meta?.total)

const updateFormType = data => {
  if (data.id){
    var isUpdated = false
    Object.keys(data).forEach(key => {
      if (formTypeDrawerData.value[key] !== data[key]){
        isUpdated = true
      }
    })
  }

  if (!isUpdated){
    isFormTypeDrawerVisible.value = false

    return
  }
  formTypeService.updateFormType(data.id, data, ()=>
  {
    fetchFormTypes()
    isFormTypeDrawerVisible.value = false
    resetErrors()
  }, onError)
}

const saveFormType = data => {
  formTypeService.addFormType(data, ()=>
  {
    fetchFormTypes()
    isFormTypeDrawerVisible.value = false
    resetErrors()

  }, onError)
}

const resetErrors = () => {
  errors.value = {
    name: [],
    code: [],
    status: [],
  }
}

const isFormTypeDrawerVisible = ref(false)
const formTypeDrawerData = ref()
const formTypeDeleteData = ref()
const formTypeDrawerType = ref('Add')

const showFormType = (formTypeData, type) => {
  formTypeDrawerData.value = formTypeData
  formTypeDrawerType.value = type  
  isFormTypeDrawerVisible.value = true
}

const deleteFormType = async (confirm, data) => {
  if(data?.id && confirm ){
    await formTypeService.deleteFormType(data.id, () => {
      fetchFormTypes()
      isConfirmDialogVisible.value = false
    }, onError)
  }
}

const showConfirmDialog = formTypeData => {
  formTypeDeleteData.value = formTypeData
  isConfirmDialogVisible.value = true
}

watch(isConfirmDialogVisible, () => {
  if (!isConfirmDialogVisible.value){
    formTypeDeleteData.value = {}
  }
})
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

       
          <VBtn
            v-if="$can('create', 'master.form_type')"
            prepend-icon="tabler-plus"
            @click="showFormType(null,'Add')"
          >
            Add New Form Type
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="formTypes"
        :items-length="totalFormTypes"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :formTypeData="item"
            @click="showFormType(item,'View')"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg
                v-if="item.avatar"
                :src="item.avatar"
              />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>
        <template #item.code="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.code }}</span>
        </template>

    
        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn 
            v-if="$can('delete', 'master.form_type')"
            @click="showConfirmDialog(item)"
          >
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <IconBtn 
            v-if="$can('update', 'master.form_type')"
            @click="showFormType(item,'Edit')"
          >
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, totalFormTypes) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(totalFormTypes / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(totalFormTypes / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>  
    <FormTypeDrawer
      v-model:isDrawerOpen="isFormTypeDrawerVisible"
      :form-type-data="formTypeDrawerData"
      :type="formTypeDrawerType ?? 'Add'"
      :errors="errors"
      @update:type="formTypeDrawerType = $event"
      @update:form-type-data="updateFormType"
      @save-form-type="saveFormType"
    />
    <ConfirmDialog
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="FormType Deleted."
      confirm-title="Deleted!"
      :data="formTypeDeleteData"
      @confirm="deleteFormType"
    />
  </section>
</template>
