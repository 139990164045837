<script setup>
import { paginationMeta } from '@/@core/utils/paginationMeta'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import feedbackSourceService from "@/services/feedbackSource.service"
import FeedbackSourceDrawer from '@/views/masters/feedbackSource/drawer/FeedbackSourceDrawer.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

const searchQuery = ref('')
const isConfirmDialogVisible = ref(false)

definePage({
  meta: {
    name: 'master-source',
    subject: 'master.feedback_source',
    action: 'read',
  },  
})

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const errors = ref({
  name: [],
  code: [],
  status: [],
})

const onError = error => {
  if (error.data){
    
    errors.value = error.data.errors
  }
}

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order.toUpperCase()
}

// Headers
const headers = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Code',
    key: 'code',
  },
  {
    title: 'Source Type',
    key: 'source_type',
  },
  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]

const {
  data: feedbackSourcesData,
  execute: fetchFeedbackSources,
} =  await useApi(createUrl('/masters/feedback-sources', {
  query: {
    paged: 1,
    q: searchQuery,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
  },
}))

const feedbackSources = computed(() => feedbackSourcesData.value?.data)
const totalFeedbackSources = computed(() => feedbackSourcesData.value?.meta?.total)

const updateFeedbackSource = data => {
  if (data.id){
    feedbackSourceService.updateFeedbackSource(data.id, data, ()=>
    {
      fetchFeedbackSources()
      isFeedbackSourceDrawerVisible.value = false
      resetErrors()
    }, onError)
   
  }
}

const saveFeedbackSource = data => {
  feedbackSourceService.addFeedbackSource(data, ()=>
  {
    fetchFeedbackSources()
    isFeedbackSourceDrawerVisible.value = false
    resetErrors()

  }, onError)
}

const resetErrors = () => {
  errors.value = {
    name: [],
    code: [],
    status: [],
  }
}

const isFeedbackSourceDrawerVisible = ref(false)
const feedbackSourceDrawerData = ref()
const feedbackSourceDeleteData = ref()
const feedbackSourceDrawerType = ref('Add')

const showFeedbackSource = (feedbackSourceData, type) => {
  feedbackSourceDrawerData.value = feedbackSourceData
  feedbackSourceDrawerType.value = type  
  isFeedbackSourceDrawerVisible.value = true
}

const deleteFeedbackSource = async (confirm, data) => {
  if(data?.id && confirm ){
    await feedbackSourceService.deleteFeedbackSource(data.id, () => {
      fetchFeedbackSources()
      isConfirmDialogVisible.value = false
    }, onError)
  }
}

const showConfirmDialog = feedbackSourceData => {
  feedbackSourceDeleteData.value = feedbackSourceData
  isConfirmDialogVisible.value = true
}

watch(isConfirmDialogVisible, () => {
  if (!isConfirmDialogVisible.value){
    feedbackSourceDeleteData.value = {}
  }
})
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

       
          <VBtn
            v-if="$can('create', 'master.feedback_source')"
            prepend-icon="tabler-plus"
            @click="showFeedbackSource({},'Add')"
          >
            Add New Feedback Source
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="feedbackSources"
        :items-length="totalFeedbackSources"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :feedbackSourceData="item"
            @click="showFeedbackSource(item,'View')"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg
                v-if="item.avatar"
                :src="item.avatar"
              />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>
        <template #item.code="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.code }}</span>
        </template>
        <template #item.status="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.status?'Active':"Inactive" }}</span>
        </template>
        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn 
            v-if="$can('delete', 'master.feedback_source')"
            @click="showConfirmDialog(item)"
          >
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <IconBtn 
            v-if="$can('update', 'master.feedback_source')"
            @click="showFeedbackSource(item,'Edit')"
          >
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, totalFeedbackSources) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(totalFeedbackSources / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(totalFeedbackSources / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>  
    <FeedbackSourceDrawer
      v-model:isDrawerOpen="isFeedbackSourceDrawerVisible"
      :feedback-source-data="feedbackSourceDrawerData"
      :type="feedbackSourceDrawerType ?? 'Add'"
      :errors="errors"
      @update:type="feedbackSourceDrawerType = $event"
      @update:feedbackSource-data="updateFeedbackSource"
      @save-feedbackSource="saveFeedbackSource"
    />
    <ConfirmDialog
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="FeedbackSource Deleted."
      confirm-title="Deleted!"
      :data="feedbackSourceDeleteData"
      @confirm="deleteFeedbackSource"
    />
  </section>
</template>
