<script setup>
import { paginationMeta } from '@/@core/utils/paginationMeta'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import tagService from '@/services/tag.service'
import TagDrawer from '@/views/masters/tag/drawer/TagDrawer.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

const searchQuery = ref('')
const isConfirmDialogVisible = ref(false)

definePage({
  meta: {
    name: 'master-tag',
    subject: 'master.tag',
    action: 'read',
  },  
})

const selectTagType =ref('RCA_CATEGORY')
const tagTypes = await getEnumValue('TagTypes', true)


// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const errors = ref({
  name: [],
  code: [],
  status: [],
})

const onError = error => {
  if (error.data){
    
    errors.value = error.data.errors
  }
}

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order.toUpperCase()
}

// Headers
const headers = [
  {
    title: 'Name',
    key: 'name',
  },

  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Unit',
    key: 'unit.name',
    sortable: false,
  },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]

const {
  data: tagsData,
  execute: fetchTags,
} =  await useApi(createUrl('/masters/tags', {
  query: {
    paged: 1,
    q: searchQuery,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
    search: {
      tag_type: selectTagType,
    },
  },
}))

const tags = computed(() => tagsData.value?.data)
const totalTags = computed(() => tagsData.value?.meta?.total)

const updateTag = data => {
  if (data.id){
    tagService.updateTag(data.id, data, ()=>
    {
      fetchTags()
      isTagDrawerVisible.value = false
      resetErrors()
    }, onError)
   
  }
}

const saveTag = data => {
  tagService.addTag(data, ()=>
  {
    fetchTags()
    isTagDrawerVisible.value = false
    resetErrors()

  }, onError)
}

const resetErrors = () => {
  errors.value = {
    name: [],
    code: [],
    status: [],
  }
}

const isTagDrawerVisible = ref(false)
const tagDrawerData = ref()
const tagDeleteData = ref()
const tagDrawerType = ref('Add')

const showTag = (tagData, type) => {
  tagDrawerData.value = tagData
  tagDrawerType.value = type  
  isTagDrawerVisible.value = true
}

const deleteTag = async (confirm, data) => {
  if(data?.id && confirm ){
    await tagService.deleteTag(data.id, () => {
      fetchTags()
      isConfirmDialogVisible.value = false
    }, onError)
  }
}

const showConfirmDialog = tagData => {
  tagDeleteData.value = tagData
  isConfirmDialogVisible.value = true
}

watch(isConfirmDialogVisible, () => {
  if (!isConfirmDialogVisible.value){
    tagDeleteData.value = {}
  }
})
</script>

<template>
  <section>
    <VCard>
      <VItemGroup
        v-model="selectTagType"
        selected-class="bg-primary "
        mandatory
      >
        <VContainer>
          <VRow>
            <VCol
              v-for="tagType in tagTypes"
              :key="tagType.value"
              cols="12"
              md="2"
            >
              <VItem
                v-slot="{isSelected, selectedClass, toggle }"
                :value="tagType.value"
              >
                <VCard
                  class="d-flex align-center cursor-pointer"
                  :class="[selectedClass]"
                  height="100"
                  dark
                  @click="toggle"
                >
                  <div
                    class="text-h3 flex-grow-1 text-center"
                    :class="isSelected ? 'text-white' : 'text-primary'"
                  >
                    {{ tagType.title }}
                  </div>
                </VCard>
              </VItem>
            </VCol>
          </VRow>
        </VContainer>
      </VItemGroup>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

       
          <VBtn
            v-if="$can('create', 'master.tag')"
            prepend-icon="tabler-plus"
            @click="showTag({},'Add')"
          >
            Add New Tag
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="tags"
        :items-length="totalTags"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :tagData="item"
            @click="showTag(item,'View')"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg
                v-if="item.avatar"
                :src="item.avatar"
              />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>
        <template #item.code="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.code }}</span>
        </template>
        <template #item.status="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.status?'Active':"Inactive" }}</span>
        </template>
        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn 
            v-if="$can('delete', 'master.tag')"
            @click="showConfirmDialog(item)"
          >
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <IconBtn 
            v-if="$can('update', 'master.tag')"
            @click="showTag(item,'Edit')"
          >
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, totalTags) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(totalTags / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(totalTags / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>  
    <TagDrawer
      v-model:isDrawerOpen="isTagDrawerVisible"
      :tag-data="tagDrawerData"
      :type="tagDrawerType ?? 'Add'"
      :errors="errors"
      @update:type="tagDrawerType = $event"
      @update:tag-data="updateTag"
      @save-tag="saveTag"
    />
    <ConfirmDialog
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="Tag Deleted."
      confirm-title="Deleted!"
      :data="tagDeleteData"
      @confirm="deleteTag"
    />
  </section>
</template>
