<script setup>
import AnalyticsFeedbackTracker from '@/@core/components/feedback/AnalyticsFeedbackTracker.vue'
import AnalyticsFormTypeTracker from '@/@core/components/feedback/AnalyticsFormTypeTracker.vue'
import AnalyticsSourceTypeTracker from '@/@core/components/feedback/AnalyticsSourceTypeTracker.vue'
import { cookieRef } from '@/@layouts/stores/config'
import StandardFeedbackTable from '@/views/feedback/feedback-panel/StandardFeedbackTable.vue'

definePage({
  meta: {
    name: 'feedback-entry',
    subject: 'feedback.feedback',
    action: 'create',
    navActiveLink: 'feedback-entry',

  },  
})

const selectedUnit = cookieRef('selectedUnit')
</script>

<template>
  <div>
    <VRow>
      <!-- page title -->
      <VCol cols="12">
        <span class="text-h3 font-weight-bold text-primary text-capitalize">
          Standard Feedback
        </span>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <AnalyticsFormTypeTracker
          :unit-id="[selectedUnit]"
          source-type="Standard" 
          :collected-by="$auth?.user?.id"
        />
      </VCol> 
     
      <VCol cols="12">
        <StandardFeedbackTable />
      </VCol>
    </VRow>
  
    <VRow class="match-height">
      <VCol
        cols="12"
        md="6"
      >
        <AnalyticsSourceTypeTracker
          :unit-id="selectedUnit"
          source-type="Standard" 
          :collected-by="$auth?.user?.id"
        />
      </VCol>
      <VCol
        cols="12"
        md="6"
      >
        <AnalyticsFeedbackTracker />
      </VCol>
    </VRow>
  </div>
</template>

