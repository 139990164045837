<script>
export const TicketType = [
  "Complaint",
  "Compliment",
  "Suggestion",
  "Special Request",
];

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const addMonths = (date, months) => {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
};

export const customDateFormat = (date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
};

export const dateRangeConfig = () => {
  return {
    mode: "range",
    maxDate: new Date(),
    dateFormat: "Y-m-d",
    enableTime: false,
    enableSeconds: false,
    noCalendar: false,
    altInput: true,
    altFormat: "F j, Y",
    altInputClass: "form-control",
    altInputPlaceholder: "Select date",
    inline: false,
    static: false,
    appendTo: document.body,
    clickOpens: true,
    defaultDate: [new Date(), new Date()],
  };
};

export const isNagativeNumber = (data) => {
  if (!data) return true;
  return data.toString().includes("-") ? true : false;
};

export const formatFloat = (number) => {
  if (Number(number) === number && number % 1 !== 0) {
    return number.toFixed(2); // Format to two decimal places
  }
  return number;
};
</script>

<script setup>
import { userAuthState } from "@/@core/auth";
import { can } from "@/@layouts/plugins/casl";
import { cookieRef } from "@/@layouts/stores/config";
import DepartmentWiseRating from "@/@core/components/feedback/DepartmentWiseRating.vue";
import TicketStatusGraph from "@/@core/components/department/TicketStatusGraph.vue";
import { computed } from "vue";
import { VCol } from "vuetify/lib/components/index.mjs";

definePage({
  meta: {
    name: "dashboard-department",
    subject: 'dashboard.department',
    action: 'read',
  },
});

const auth = userAuthState();
const canAccessUnit = can("read", "masters.unit");

const { data: unitsData } = await useApi(
  createUrl("/masters/units", {
    query: {
      paged: 1,
    },
  }),
  {
    beforeFetch: ({ cancel }) => {
      if (!canAccessUnit) cancel();
    },
  }
);
const units = computed(() => {
  if (unitsData.value?.data) {
    return unitsData.value?.data;
  }

  return [
    {
      id: auth?.user?.unit_id,
      name: auth?.user?.unit_name,
    },
  ];
});
const unitId = ref(cookieRef("selectedUnit") ?? null);

const { data: departments } = await useApi(
  createUrl("masters/departments", {
    query: {
      select: ["id", "name"],
    },
  })
);
const departmentId = ref(null);

const quickDateRange = ref("Last 30 days");

const quickDateRangeOptions = [
  {
    title: "Today",
    value: `${customDateFormat(new Date())}`,
  },
  {
    title: "Yesterday",
    value: `${customDateFormat(addDays(new Date(), -1))}`,
  },
  {
    title: "Last 7 days",
    value: `${customDateFormat(addDays(new Date(), -6))} to ${customDateFormat(
      new Date()
    )}`,
  },
  {
    title: "Last 30 days",
    value: `${customDateFormat(addDays(new Date(), -30))} to ${customDateFormat(
      new Date()
    )}`,
  },
  {
    title: "This month",
    value: `${customDateFormat(startOfMonth(new Date()))} to ${customDateFormat(
      new Date()
    )}`,
  },
  {
    title: "Last month",
    value: `${customDateFormat(
      startOfMonth(addMonths(new Date(), -1))
    )} to ${customDateFormat(endOfMonth(addMonths(new Date(), -1)))}`,
  },
  {
    title: "Custom",
    value: null,
  },
];

const fromDate = ref(customDateFormat(addDays(new Date(), -30)));
const toDate = ref(customDateFormat(new Date()));

const setQuickDateRange = (value) => {
  dateRange.value = value?.value;
};
const dateRange = ref();
const dateRangeOptions = ref({
  ...dateRangeConfig(),
  onChange: (selectedDates, dateStr, instance) => {
    fromDate.value = customDateFormat(selectedDates[0]);
    if (selectedDates[1]) toDate.value = customDateFormat(selectedDates[1]);
    else {
      toDate.value = customDateFormat(selectedDates[0]);
    }
    dateRange.value = dateStr;
  },
  defaultDate: [
    customDateFormat(addDays(new Date(), -30)),
    customDateFormat(new Date()),
  ],
});

const dateRangeValue = computed(() => {
  return [fromDate.value, toDate.value];
});

// Total Feedback
const { data: totalFeedbackData } = useApi(
  createUrl("/dashboard/feedbacks/total-count", {
    query: {
      search: {
        created_at: dateRangeValue,
        unit_id: unitId,
        // 'questions.department_id': departmentId,
      },
    },
  })
);

// Ticket Type
const { data: totalTicketTypeData } = await useApi(
  createUrl("/dashboard/tickets/ticket-type", {
    query: {
      search: {
        created_at: dateRangeValue,
        unit_id: unitId,
        department_id: departmentId,
      },
    },
  })
);
const ticketTypeChart = computed(() => {
  let labels = [];
  let series = [];
  let colors = [];

  if (totalTicketTypeData.value) {
    totalTicketTypeData.value.tickets.forEach(({ ticket_type, percentage }) => {
      TicketType.forEach((type) => {
        if (ticket_type == type) {
          labels.push(ticket_type);
          series.push(parseFloat(percentage));
          colors.push(
            `rgba(var(--v-theme-${
              resolveTicketTypeColor(ticket_type).color
            }),1)`
          );
        }
      });
    });
  }

  return { labels, series, colors };
});
const ticketTypeChartConfig = computed(() => {
  return {
    chart: {
      height: 157,
      width: 130,
      parentHeightOffset: 0,
      type: "donut",
    },
    labels: ticketTypeChart.value?.labels ?? [],
    colors: ticketTypeChart.value?.colors ?? [],
    stroke: { width: 0 },
    dataLabels: {
      enabled: false,
      formatter(val) {
        return `${Number.parseInt(val)}%`;
      },
    },
    legend: { show: false },
    tooltip: { theme: false },
    grid: { padding: { top: 0 } },
    plotOptions: {
      pie: {
        donut: {
          size: "75%",
          labels: {
            show: true,
            value: {
              fontSize: "1.125rem",
              color:
                "rgba(var(--v-theme-on-background), var(--v-high-emphasis-opacity))",
              fontWeight: 500,
              offsetY: -15,
              formatter(val) {
                return `${Number.parseInt(val)}%`;
              },
            },
            name: { offsetY: 20 },
            total: {
              show: true,
              fontSize: "15px",
              label: "Total",
              color:
                "rgba(var(--v-theme-on-background), var(--v-disabled-opacity))",
              formatter() {
                return totalTicketTypeData.value?.total_ticket_count ?? 0;
              },
            },
          },
        },
      },
    },
  };
});

const ticketTypeCount = computed(() => {
  let data = [];

  TicketType.forEach((type) => {
    data.push({
      name: type,
      count:
        totalTicketTypeData.value.tickets.find((x) => x.name == type)?.count ??
        0,
    });
  });

  return data;
});

// P-Sat
const { data: departmentPSatData } = await useApi(
  createUrl("/dashboard/feedbacks/p-sat/department", {
    query: {
      search: {
        unit_id: unitId,
        created_at: dateRangeValue,
      },
    },
  })
);
const pSatData = computed(() => {
  if (!departmentPSatData.value) return null;

  if (departmentId.value) {
    const data = departmentPSatData.value.find(
      (x) => x.department_id == departmentId.value
    );
    return {
      overall_p_sat: formatFloat(data?.overall_p_sat) ?? null,
      p_sat_percentage: formatFloat(data?.p_sat_percentage) ?? null,
    };
  } else {
    const data = departmentPSatData.value.reduce(
      (acc, obj) => {
        acc.overall_p_sat += obj.overall_p_sat || 0;
        acc.p_sat_percentage += obj.p_sat_percentage || 0;
        return acc;
      },
      { overall_p_sat: 0, p_sat_percentage: 0 }
    );
    return {
      overall_p_sat: formatFloat(data?.overall_p_sat) ?? null,
      p_sat_percentage: formatFloat(data?.p_sat_percentage) ?? null,
    };
  }
});
</script>
<template>
  <div>
    <VRow>
      <VCol cols="12">
        <span class="text-h3 font-weight-bold text-primary text-capitalize">
          Department Analytics
        </span>
      </VCol>
    </VRow>
    <VRow class="mb-4">
      <VCol md="12" sm="12" xs="12">
        <VCard>
          <VCardText>
            <span class="text-h6 font-weight-bold text-primary text-capitalize">
              Filter Dashboard By Date
            </span>
            <VRow align="center">
              <VCol cols="12" md="2" sm="6" xs="4">
                <AppSelect
                  v-model="quickDateRange"
                  label="Quick Range"
                  return-object
                  :item-title="(item) => item.title"
                  :item-value="(item) => item.title"
                  :items="quickDateRangeOptions"
                  @update:model-value="setQuickDateRange"
                />
              </VCol>
              <VCol cols="12" md="4" sm="6" xs="12">
                <AppDateTimePicker
                  v-model="dateRange"
                  label="Range"
                  placeholder="Select date"
                  :config="dateRangeOptions"
                />
              </VCol>

              <VCol
                v-if="departments.length > 1"
                cols="12"
                md="3"
                sm="6"
                xs="12"
              >
                <AppSelect
                  v-model="departmentId"
                  clearable
                  label="Select Department"
                  placeholder="Select Department"
                  :items="departments"
                  :item-title="(item) => item.name"
                  :item-value="(item) => item.id"
                />
              </VCol>

              <VCol v-if="units.length > 1" cols="12" md="3" sm="6" xs="12">
                <AppSelect
                  v-model="unitId"
                  clearable
                  label="Select Unit"
                  placeholder="Select Unit"
                  :items="units"
                  :item-title="(item) => item.name"
                  :item-value="(item) => item.id"
                />
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>

    <VRow class="py-6">
      <!-- 👉 Welcome -->
      <VCol
        cols="12"
        md="6"
        :class="$vuetify.display.mdAndUp ? 'border-e' : 'border-b'"
      >
        <div class="pe-3">
          <VRow>
            <VCol
              cols="12"
              md="6" sm="6"
              v-for="{ title, value, subValue, icon, color } in [
                {
                  title: 'Total Feedback',
                  icon: 'tabler-clipboard-text',
                  color: 'primary',
                  value: totalFeedbackData?.total_feedback_count ?? 0,
                  //subValue: `(${
                  //  totalFeedbackData?.total_feedback_difference ?? 0
                  //} %)`,
                  subValue: totalFeedbackData?.total_feedback_difference ?? 0,
                },
                {
                  title: 'Overall P-Sat Score',
                  icon: 'tabler-chart-line',
                  color: 'info',
                  value: pSatData?.overall_p_sat ?? 0,
                },
                {
                  title: 'P-Sat Percentage',
                  icon: 'tabler-chart-pie',
                  color: 'success',
                  value: `${pSatData?.p_sat_percentage ?? 0} %`,
                },
                {
                  title: 'Total Ticket',
                  icon: 'tabler-ticket',
                  color: 'warning',
                  value: totalTicketTypeData?.total_ticket_count ?? 0,
                  subValue: totalTicketTypeData?.total_ticket_difference ?? 0
                },
              ]"
              :key="title"
            >
              <div class="d-flex align-center">
                <VAvatar
                  variant="tonal"
                  :color="color"
                  rounded
                  size="54"
                  class="text-primary me-4"
                >
                  <VIcon :icon="icon" size="38" />
                </VAvatar>
                <div>
                  <h6 class="text-h6 text-medium-emphasis">
                    {{ title }}
                  </h6>
                  <div class="d-flex gap-4">
                    <h4 class="text-h4" :class="`text-${color}`">
                      {{ value }}
                    </h4>
                    <VChip
                      v-if="subValue"
                      class="mt-1" label size="small"
                      :color="isNagativeNumber(subValue) ? 'error' : 'success'" 
                    >
                      <VIcon
                        :icon="isNagativeNumber(subValue) ? 'tabler-arrow-down' : 'tabler-arrow-up'"
                        class="mr-1"
                      />
                      {{ subValue }}%
                    </VChip>
                  </div>
                </div>
              </div>
            </VCol>
          </VRow>
        </div>
      </VCol>

      <!-- Ticket Type -->
      <VCol cols="12" md="6">
        <div class="ps-3 d-flex flex-row justify-space-between align-center">
          
          <!-- <div class="d-flex flex-column ps-3">
            <h5 class="text-h5 mb-1 text-no-wrap">
              Total Ticket
            </h5>
            <div class="text-body-1 mb-7">
              {{ totalTicketTypeData?.difference_date_range }}
            </div>

            <div class="d-flex gap-4">
              <h4 class="text-h4">
                {{ totalTicketTypeData?.total_ticket_count }}
              </h4>

              <VChip
                class="mt-1"
                color="success"
                label
                size="small"
              >
                {{ totalTicketTypeData?.total_ticket_difference }}%
              </VChip>

              <VChip
                v-if="totalTicketTypeData?.total_ticket_difference"
                class="mt-1" label size="small"
                :color="isNagativeNumber(totalTicketTypeData?.total_ticket_difference) ? 'error' : 'success'" 
              >
                <VIcon
                  :icon="isNagativeNumber(totalTicketTypeData?.total_ticket_difference) ? 'tabler-arrow-down' : 'tabler-arrow-up'"
                  class="mr-1"
                />
                {{ totalTicketTypeData?.total_ticket_difference }}%
              </VChip>
            </div>
          </div> -->

          <div class="me-auto">
            <VRow no-gutters>
              <VCol
                v-for="ticketType in ticketTypeCount"
                :key="ticketType.ticket_type"
                class="my-4"
                cols="12"
                md="6"
                sm="6"
              >
                <VList class="card-list" style="background: transparent">
                  <VListItem>
                    <VListItemTitle class="font-weight-medium">
                      {{ ticketType.name }}
                    </VListItemTitle>
                    <VListItemSubtitle class="text-disabled">
                      {{ ticketType.count }}
                    </VListItemSubtitle>
                    <template #prepend>
                      <VAvatar
                        rounded
                        size="34"
                        :color="resolveTicketTypeColor(ticketType.name).color"
                        variant="tonal"
                      >
                        <VIcon
                          :icon="resolveTicketTypeColor(ticketType.name).icon"
                        />
                      </VAvatar>
                    </template>
                  </VListItem>
                </VList>
              </VCol>
            </VRow>

            <!-- <VList class="card-list" style="background: transparent;">
                <VListItem
                  v-for="ticketType in totalTicketTypeData.tickets"
                  :key="ticketType.ticket_type"
                >
                  <VListItemTitle class="font-weight-medium">
                    {{ ticketType.name }}
                  </VListItemTitle>
                  <VListItemSubtitle class="text-disabled">
                    {{ ticketType.count }}
                  </VListItemSubtitle>
                  <template #prepend>
                    <VAvatar
                      rounded
                      size="34"
                      :color="resolveTicketTypeColor(ticketType.name).color"
                      variant="tonal"
                    >
                      <VIcon :icon="resolveTicketTypeColor(ticketType.name).icon" />
                    </VAvatar>
                  </template>
                </VListItem>
              </VList>
            </div> -->

            <!-- <h4 class="text-h4 mb-2">
              231<span class="text-medium-emphasis">h</span> 14<span class="text-medium-emphasis">m</span>
            </h4>
            <div>
              <VChip
                color="success"
                label
                size="small"
              >
                {{ totalTicketTypeData?.total_ticket_difference }}%
              </VChip>
            </div> -->
          </div>
          <div class="">
            <VueApexCharts
              type="donut"
              height="150"
              width="150"
              :options="ticketTypeChartConfig"
              :series="ticketTypeChart.series"
            />
          </div>
        </div>
      </VCol>
    </VRow>

    <VRow class="py-2" v-if="can('view', 'department_dashboard.widget.department_wise_rating')">
      <VCol cols="12">
        <DepartmentWiseRating
          :key="unitId + departmentId + fromDate + toDate"
          :from="fromDate"
          :to="toDate"
          :unit-id="unitId"
          :department-id="departmentId"
        />
      </VCol>
    </VRow>

    <VRow class="py-2" v-if="can('view', 'department_dashboard.widget.ticket_status')">
      <VCol cols="12">
        <TicketStatusGraph
          :key="unitId + departmentId + fromDate + toDate"
          :from="fromDate"
          :to="toDate"
          :unit-id="unitId"
          :department-id="departmentId"
        />
      </VCol>
    </VRow>
  </div>
</template>
