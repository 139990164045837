<script setup>
import QRCode from '@chenfengyuan/vue-qrcode'

definePage({
  meta: {
    name: 'utility-qrcode',
    subject: 'utility.qr_code',
    action: 'read',
  },  
})

const avatarLocal = ref(null)
const refInputEl = ref()
const size= ref(200)

const changeAvatar = file => {
  const fileReader = new FileReader()
  const { files } = file.target
  if (files && files.length) {
    fileReader.readAsDataURL(files[0])
    fileReader.onload = () => {
      if (typeof fileReader.result === 'string')
        avatarLocal.value = fileReader.result
      const canvas = document.querySelector('canvas')

      onReady(canvas)
    }
  }
}

const qrcodeLink = ref(import.meta.env.VITE_BASE_URL +'/feedback-collect')

const unit = ref()
const sourceType = ref()
const location = ref()
const formType =ref()


// watch location and formType and add into qrcodeLink
watch([location, formType, sourceType, unit], ([location, formType]) => {
  let url = import.meta.env.VITE_BASE_URL +'/feedback-collect?'

  if(unit.value){
    url += `&u=${btoa(unit.value?.id ?? unit.value)}`
  }

  if(sourceType.value){
    url += `&s=${btoa(sourceType.value?.id ?? sourceType.value)}`
  }
  if (location){
    url += `&l=${btoa(location?.id)}`
  }
  if (formType){
    url += `&f=${btoa(formType?.id)}`
  }
  qrcodeLink.value = url
}, { immediate: true })

const {  data: sourceTypes } = await useApi(createUrl('masters/feedback-sources', 
  { 
    query: { 
      select: ['id', 'name'],
      search: {
        source_type: "QrCode",
      },
    },
  }))

if(sourceTypes.value && sourceTypes.value.length ==1){
  sourceType.value = sourceTypes.value[0].id
}
const { isFetching: unitLoading, data: units } = await useApi(createUrl('/masters/units'))
if(units.value && units.value.length ==1){
  unit.value = units.value[0].id
}


const locationType =ref()

const { data: locationTypes } = await useApi(createUrl('/masters/location-types', {
  query: {
    search: {
      unit_id: unit,
    },
    select: ['id', 'name'],
  },

}), {
  beforeFetch: ({ url, options, cancel }) =>{
    if(!unit.value)
    {
      cancel()
    }
  },
})

const getSelectedLocationType = computed(() => {
  return locationType.value?.id??null
})

const { isFetching: locationLoading,  data: locations } =useApi(createUrl('/masters/locations', {
  query: {
    search: {
      location_type_id: getSelectedLocationType,
      unit_id: unit,
    },
    select: ['id', 'name'],
  },

}), {
  beforeFetch: ({ url, options, cancel }) =>{
    if(!(unit.value && locationType.value))
    {
      cancel()
    }
  },
})

const { isFetching: isFetchingFormTypes,  data: formTypes } =useListFetchingService('/masters/form-types', {
  query: {
    select: ['id', 'name'],
    unit_id: unit,
  },

})


const onReady=canvas=>{
  const context = canvas.getContext('2d')
  const image = new Image()
  if (!unit.value) return

  // Add form type name to the qr code at the bottom
  context.font = 'bold 15px Arial'
  context.fillStyle = '#000'
  context.textAlign = 'center'

  // wrap to second line if location is selected
  let text = formType.value?.name ?? 'General'
  
  if(location.value){
    text = text+ ' - ' + location.value?.name
  }  
  context.fillText(text, canvas.width / 2, canvas.height-2)

  image.src =  avatarLocal.value
  image.onload = () => {
    const coverage = 0.15
    const width = Math.round(canvas.width * coverage)
    const x = (canvas.width - width) / 2

    image.setAttribute('crossorigin', 'anonymous') 

    drawImage(context, image, x, x, width, width)
  }
}

const  drawImage=(context, image, x, y, width, height, radius = 4) =>{
  context.shadowOffsetX = 0
  context.shadowOffsetY = 2
  context.shadowBlur = 4
  context.shadowColor = '#00000040'
  context.lineWidth = 8
  context.beginPath()
  context.moveTo(x + radius, y)
  context.arcTo(x + width, y, x + width, y + height, radius)
  context.arcTo(x + width, y + height, x, y + height, radius)
  context.arcTo(x, y + height, x, y, radius)
  context.arcTo(x, y, x + width, y, radius)
  context.closePath()
  context.strokeStyle = '#fff'
  context.stroke()
  context.clip()
  context.fillStyle = '#fff'
  context.fillRect(x, x, width, height)
  context.drawImage(image, x, x, width, height)   
}

const downloadQRCode = () => {
  var link = document.createElement('a')

  link.download = downloadOptions.value.name + '.' + downloadOptions.value.extension
  link.href = document.querySelector('canvas').toDataURL()
  link.click()
  
}

const copyQRCodeLink = () => {
  navigator.clipboard.writeText(qrcodeLink.value)
}


const downloadOptions =computed(()=>{

  let filename =  formType.value?.name ?? 'General'
  if(location.value){
    filename = filename + ' - ' + locationType.value?.name + ' - ' + location.value?.name
  }
  
  return { name: filename, extension: 'png' }
})
</script>

<template>
  <div>
    <VRow class="mb-4">
      <!-- page title -->
      <VCol
        cols="12"
        lg="12"
        md="6"
      >
        <span class="text-h3 font-weight-bold text-primary text-capitalize">
          Feedback Qr Code Generator
        </span>
      </VCol>
    </VRow>
   
    <VCard>
      <VCardTitle>
        <p class="text-h4 text-capitalize">
          Qr Code Generator
        </p>
      </VCardTitle>
      <VCardSubtitle>
        <p class="text-h6 font-weight-bold text-primary text-capitalize">
          Generate QR code for feedback collection.
        </p>
      </VCardSubtitle>
        
      <VRow>
        <VCol
          cols="12"
          md="6"
          sm="12"
        >
          <VCardItem>
            <VCol cols="12">
              <VImg
                v-if="avatarLocal"
                :src="avatarLocal"
                width="100"
                height="100"
                class="rounded-lg cursor-pointer"
                @click="refInputEl.click()"
              />
              <VBtn
                v-else
                color="primary"
                @click="refInputEl.click()"
              >
                Add Image
              </VBtn>
              <input
                ref="refInputEl"
                type="file"
                name="file"
                accept=".jpeg,.png,.jpg,GIF"
                hidden
                @input="changeAvatar"
              >
            </VCol>
            <VCol cols="12">
              <AppAutocomplete
                v-if="units?.length>1"
                v-model="unit"
                label="Unit"
                :items="units"
                :loading="unitLoading"
                :item-title="item => item.name"
                :item-value="item => item.id"
                persistent-hint
                placeholder="Select unit"
              />
            </VCol>
                
            <VCol cols="12">
              <AppAutocomplete
                v-if="sourceTypes?.length>1"
                v-model="sourceType"
                :items="sourceTypes"
                :item-title="item => item.name"
                :item-value="item => item.id"
                eager
                required
                :rules="[requiredValidator]"
                return-object
                placeholder="Search Qrcode Type"
                label="Qr Code Type"
                :menu-props="{ maxHeight: '200px' }"
              />
            </VCol>
            <VCol cols="12">
              <AppSelect
                v-model="locationType"
                :disabled="!unit && !locationType"
                :items="locationTypes??[]"
                :item-title="item => item.name"
                :item-value="item => item.id"
                eager
                clearable
                return-object
                placeholder="Select Location Type"
                label="Location Type"
                :menu-props="{ maxHeight: '200px' }"
              />
            </VCol>
            <VCol cols="12">
              <AppAutocomplete
                v-model="location"
                :disabled="!(unit && locationType)"
                :loading="locationLoading"
                :items="locations??[]"
                :item-title="item => item.name"
                :item-value="item => item.id"
                eager
                clearable
                return-object
                placeholder="Search Location"
                label="Location"
                :menu-props="{ maxHeight: '200px' }"
              />
            </VCol>
            <VCol cols="12">
              <AppCombobox
                v-model="formType"
                :disabled="!unit"
                label="Form Type"
                :items="formTypes"
                return-object
                :loading="isFetchingFormTypes"
                :item-title="item => item.name"
                :item-value="item => item.id"
                persistent-hint
                placeholder="Select form type"
              />
            </VCol>
            <VCol cols="12">
              <VSlider
                v-model="size"
                :max="1000"
                :min="100"
                :step="50"
                thumb-label
              />
            </VCol>
            <VCol cols="12">
              <VRow v-if="unit">
                <VCol cols="6">
                  <VBtn
                    color="primary"
                    @click="downloadQRCode"
                  >
                    Download QR Code
                  </VBtn>
                </VCol>
                <VCol cols="6">
                  <VBtn
                    color="success"
                    @click="copyQRCodeLink"
                  >
                    Copy QR Link
                  </VBtn>
                </VCol>
              </VRow>
            </VCol>
          </VCardItem>
        </VCol>
            
        <VCol
          cols="12"
          md="6"
          sm="12"
        >
          <VContainer>
            <QRCode
              v-if="unit"
              :value="qrcodeLink"
              :options="{
                errorCorrectionLevel: 'Q',
                width: size,
              }"
              @ready="onReady"
            />
          </VContainer>
        </VCol>
        <input
          ref="refInputEl"
          type="file"
          name="file"
          accept=".jpeg,.png,.jpg,GIF"
          hidden
          @input="changeAvatar"
        >
      </VRow>
    </VCard>
  </div>
</template>

